



































import OptimierungFaktenSection from '@/components/contents/ShopOptimierung/OptimierungFaktenSection.vue';
import OptimierungOnlineShopsPflegenSection from '@/components/contents/ShopOptimierung/OptimierungOnlineShopsPflegenSection.vue';
import OptimierungSuchmaschinenSection from '@/components/contents/ShopOptimierung/OptimierungSuchmaschinenSection.vue';
import OptimierungVisionSection from '@/components/contents/ShopOptimierung/OptimierungVisionSection.vue';
import OptimierungUpgradeSection from '@/components/contents/ShopOptimierung/OptimierungUpgradeSection';
import ContentColumnsService from '@/components/elements/ContentColumnsService.vue';
import ContentColumnsServices from '@/components/elements/ContentColumnsServices.vue';
import ContentColumnsTextImage from '@/components/elements/ContentColumnsTextImage.vue';
import ContentSection from '@/components/elements/ContentSection.vue';
import ContentShowCaseItem from '@/components/elements/ContentShowCaseItem.vue';
import ContentThin from '@/components/elements/ContentThin.vue';
import NavRouterLinks from '../../elements/NavRouterLinks.vue';
import UndersideHeader from '../../elements/UndersideHeader.vue';
import {Component, Prop, Vue} from "vue-property-decorator";
import VBtn from "vuetify/src/components/VBtn/VBtn";

@Component({
             components: {
               OptimierungVisionSection,
               OptimierungSuchmaschinenSection,
               OptimierungFaktenSection,
               OptimierungOnlineShopsPflegenSection,
               OptimierungUpgradeSection,
               UndersideHeader,
               NavRouterLinks,
               VBtn,
               ContentSection,
               ContentThin,
               ContentColumnsService,
               ContentColumnsServices,
               ContentColumnsTextImage,
               ContentShowCaseItem
             }
           })
export default class ShopOptimierungContent extends Vue {
  @Prop({default: {menuActive: false}}) protected pageData!: object;
  private pageHeaderTitle: string = "Verbessern Sie Ihren Online Shop";
  private pageHeaderTags: string = "Erfolgschancen von Webshops steigern";
  private pageHeaderSubLine: string = "Sie sehen Optimierungspotential in Ihrem Online Shop, wissen aber nicht " +
    "so recht, wo Sie ansetzen sollen? Wir unterstützen Sie. Um langfristig Erfolg im Onlinehandel zu verzeichnen, " +
    "entwickeln wir Ideen zu Verbesserung Ihres Webauftrittes. Dabei finden wir nicht nur technische " +
    "Lösungsansätze, sondern betrachten ganzheitlich alle Prozesse in Ihrem Shop System. <br><br> ";
}
