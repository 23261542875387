import { render, staticRenderFns } from "./OptimierungSuchmaschinenSection.vue?vue&type=template&id=80884f46&scoped=true&"
import script from "./OptimierungSuchmaschinenSection.vue?vue&type=script&lang=ts&"
export * from "./OptimierungSuchmaschinenSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80884f46",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VExpansionPanel } from 'vuetify/lib'
import { VExpansionPanelContent } from 'vuetify/lib'
installComponents(component, {VCard,VCardText,VExpansionPanel,VExpansionPanelContent})
