
































import ContentSection from '@/components/elements/ContentSection.vue';
import HrefLink from '@/components/elements/HrefLink.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {HrefLink, ContentSection}
           })
export default class OptimierungOnlineShopsPflegenSection extends Vue {
  @Prop({default: '00'}) private sectionNr!: string;
  @Prop({default: ''}) private sectionLabel!: string;
}
