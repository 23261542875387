



























import ContentSection from '@/components/elements/ContentSection.vue';
import HrefLink from '@/components/elements/HrefLink.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {HrefLink, ContentSection}
           })
export default class OptimierungUpgradeSection extends Vue {
  @Prop({default: '00'}) private sectionNr!: string;
  @Prop({default: ''}) private sectionLabel!: string;

  scrollFix(hashbang){
    (hashbang)? location.href = hashbang:'';
  }

  mounted(){
    setTimeout(() => this.scrollFix(this.$route.hash), 1)
  }
}
