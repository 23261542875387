


















import {Component, Prop, Vue} from "vue-property-decorator";
import ContentColumnsService from "../elements/ContentColumnsService";
@Component({
             components: {
               ContentColumnsServices
             }
           })
export default class ContentColumnsServices extends Vue {


}
