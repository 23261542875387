





































import ContentSection from '@/components/elements/ContentSection.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {ContentSection}
           })
export default class OptimierungVisionSection extends Vue {
  @Prop({default: '00'}) private sectionNr!: string;
  @Prop({default: ''}) private sectionLabel!: string;
}
