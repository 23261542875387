









import ShopOptimierungContent from '@/components/contents/ShopOptimierung/ShopOptimierungContent.vue';
import BaseSite from '@/views/BaseSite.vue';
import Page from '@/components/elements/Page.vue';

import { Component, Vue } from 'vue-property-decorator';
@Component({
             components: {
               ShopOptimierungContent,
               Page,

             },
           })
export default class SiteShopOptimierung extends BaseSite {
  private optimierungPage: object = {menuActive:false};

}
