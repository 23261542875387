




import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {}
           })
export default class HrefLink extends Vue {
  @Prop({default:''}) private text!:string;
  @Prop({default:''}) private link!:string;
}
