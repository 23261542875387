
























import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {}
           })
export default class ContentColumnsServices extends Vue {
  @Prop({default: "My Service"}) protected serviceTitle!: string;
  @Prop({default: "€ 1.000"}) protected servicePrice!: string;
  @Prop({default: ""}) protected serviceClass!: string;

}
